import { useState } from "react";
import {
  Box,
  IconButton,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { Menu, Close } from "@mui/icons-material";
import { HashLink as Link } from "react-router-hash-link";
import logoImage from "../../assets/logo.jpg";

const Navbar = () => {
  const [isMobileMenuToggled, setIsMobileMenuToggled] = useState(false);
  const isMobile = useMediaQuery("(max-width: 1000px)");
  const { palette } = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: palette.secondary.main,
        padding: "0.5rem",
      }}
    >
      {/* Logo */}
      <img src={logoImage} alt="logo" height="70px" width="180px" />

      {/* Desktop Navigation */}
      {!isMobile && (
        <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          gap: "2em",
        }}
        >
          <Link smooth to="#about" style={{ textDecoration: "none" }}>
            <Typography
              variant="h3"
              sx={{
                fontWeight: "700",
                color: palette.text.alt,
                "&:hover": {
                  color: palette.text.main,
                },
              }}
              underline="none"
            >
              ABOUT US
            </Typography>
          </Link>
          <Link smooth to="#service" style={{ textDecoration: "none" }}>
            <Typography
              variant="h3"
              sx={{
                fontWeight: "700",
                color: palette.text.alt,
                "&:hover": {
                  color: palette.text.main,
                },
              }}
              underline="none"
            >
              OUR SERVICES
            </Typography>
          </Link>
          <Link smooth to="#contact" style={{ textDecoration: "none" }}>
            <Typography
              variant="h3"
              sx={{
                fontWeight: "700",
                color: palette.text.alt,
                "&:hover": {
                  color: palette.text.main,
                },
              }}
              underline="none"
            >
              CONTACT US
            </Typography>
          </Link>
        </Box>
      )}

      {/* Mobile Navigation Toggle Button */}
      {isMobile && (
        <IconButton
          onClick={() => setIsMobileMenuToggled(!isMobileMenuToggled)}
        >
          <Menu />
        </IconButton>
      )}

      {/* Mobile Navigation */}
      {isMobile && isMobileMenuToggled && (
        <Box
          position="fixed"
          right="0"
          bottom="0"
          height="100%"
          zIndex="10"
          maxWidth="500px"
          minWidth="300px"
          backgroundColor={palette.secondary.main}
        >
          {/* Close Icon */}
          <Box display="flex" justifyContent="flex-end" p="1rem">
            <IconButton onClick={() => setIsMobileMenuToggled(false)}>
              <Close />
            </IconButton>
          </Box>

          {/* Menu Items */}
          <Box
            height="auto"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap="3rem"
          >
            <Link smooth to="#about" style={{ textDecoration: "none" }}>
              <Typography
                variant="h3"
                sx={{
                  fontWeight: "700",
                  color: palette.text.alt,
                  "&:hover": {
                    color: palette.text.main,
                  },
                }}
                underline="none"
              >
                ABOUT US
              </Typography>
            </Link>
            <Link smooth to="#service" style={{ textDecoration: "none" }}>
              <Typography
                variant="h3"
                sx={{
                  fontWeight: "700",
                  color: palette.text.alt,
                  "&:hover": {
                    color: palette.text.main,
                  },
                }}
                underline="none"
              >
                OUR SERVICES
              </Typography>
            </Link>
            <Link smooth to="#contact" style={{ textDecoration: "none" }}>
              <Typography
                variant="h3"
                sx={{
                  fontWeight: "700",
                  color: palette.text.alt,
                  "&:hover": {
                    color: palette.text.main,
                  },
                }}
                underline="none"
              >
                CONTACT US
              </Typography>
            </Link>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Navbar;
