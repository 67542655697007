import React from "react";
import BackgroundImage from "../../assets/cover.jpg";
import { HashLink as Link } from "react-router-hash-link";
import { Typography, Button, useTheme } from "@mui/material";
import FlexBetween from "../reusables/flexBetween.jsx";

const Landing = () => {
  const { palette } = useTheme();

  return (
    <div
      className="landing"
      style={{
        backgroundImage: `url(${BackgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <FlexBetween
        sx={{
          height: "100vh",
          width: "100%",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Typography variant="h3" sx={{
              fontSize: "50px",
              fontWeight: "700",
              color: palette.text.alt,
            }} gutterBottom>
          ANM Electrical Limited
        </Typography>
        <Link to="#contact">
          <Button
            variant="outlined"
            sx={{
              fontSize: "30px",
              fontWeight: "700",
              backgroundColor: palette.primary.main,
              color: palette.text.main,
              "&:hover": {
                backgroundColor: "transparent",
                color: palette.text.alt
              },
            }}
          >
            Contact Us
          </Button>
        </Link>
      </FlexBetween>
    </div>
  );
};

export default Landing;
