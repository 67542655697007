import React from "react";
import Tree from "../../assets/tree.jpg";
import { Box, useTheme, useMediaQuery, Typography } from "@mui/material";

const About = () => {
  const { palette } = useTheme();
  const isMobile = useMediaQuery("(max-width: 600px)");

  return (
    <div
      id="about"
      style={{
        display: "flex",
        flexDirection: useMediaQuery("(max-width: 1400px)") ? "column" : "row",
        justifyContent: "center",
        alignItems: "center",
        padding: "12% 8%",
        gap: useMediaQuery("(max-width: 1400px)") ? "2em" : "8em",
        backgroundColor: palette.primary.main,
      }}
    >
      <div>
        <img
          src={Tree}
          style={{
            position: "relative",
            maxWidth: "95%",
          }}
        />
      </div>

      <Box>
        <Typography
          marginBottom="1rem"
          variant="h2"
          color={palette.text.main}
          sx={{
            fontSize: isMobile ? "50px" : "60px",
            fontWeight: "400",
          }}
        >
          About Us
        </Typography>
        <Typography
        marginBottom="1rem"
          variant="h3"
          color={palette.text.main}
          sx={{
            fontSize: isMobile ? "60px" : "70px",
            fontWeight: "500",
          }}
        >
          Certified Electrician
        </Typography>
        <Typography
          color={palette.text.main}
          sx={{
            fontSize: isMobile ? "24px" : "28px",
          }}
        >
          With over 15 years of expert craftsmanship, we light up your world
          with safe, reliable, and efficient electrical solutions for every need
          - from cozy homes to bustling businesses and industrial giants.
          Experience personalized service tailored just for you and ignite your
          space with brilliance. Request your free quote today and let's spark
          something amazing together!
        </Typography>
      </Box>
    </div>
  );
};

export default About;
