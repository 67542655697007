import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#303f80",
    },
    secondary: {
      main: "#dedede",
    },
    text: {
      main: "#ffffff",
      alt: "#303f80",
    },
    background: {
      default: "#f0f0f0",
    },
  },
  typography: {
    fontFamily: ["Rubik", "sans-serif"].join(","),
    fontSize: 12,
    h1: {
      fontFamily: ["Rubik", "sans-serif"].join(","),
      fontSize: 40,
    },
    h2: {
      fontFamily: ["Rubik", "sans-serif"].join(","),
      fontSize: 32,
    },
    h3: {
      fontFamily: ["Rubik", "sans-serif"].join(","),
      fontSize: 24,
    },
    h4: {
      fontFamily: ["Rubik", "sans-serif"].join(","),
      fontSize: 20,
    },
    h5: {
      fontFamily: ["Rubik", "sans-serif"].join(","),
      fontSize: 16,
    },
    h6: {
      fontFamily: ["Rubik", "sans-serif"].join(","),
      fontSize: 14,
    },
  },
});

export default theme;
