import React from "react";
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import BusinessIcon from "@mui/icons-material/Business";
import FactoryIcon from "@mui/icons-material/Factory";
import WidgetWrapper from "../reusables/widgetWrapper.jsx";

const Service = () => {
  const { palette } = useTheme();
  const isMobile = useMediaQuery("(max-width: 1175px)");
  const { cardWidth, cardHeight } = { cardWidth: "290px", cardHeight: "420px" };
  return (
    <div
      id="service"
      style={{
        backgroundColor: palette.secondary.main,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "12% 8%",
        gap: "2em",
      }}
    >
      <Typography
        color={palette.primary.main}
        sx={{
          fontWeight: "700",
          fontSize: "42px",
        }}
      >
        Our Services
      </Typography>

      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          gap: "2rem",
        }}
      >
        <WidgetWrapper
          sx={{
            backgroundColor: palette.primary.main,
            width: cardWidth,
            height: cardHeight,
          }}
        >
          <HomeIcon style={{ fontSize: "80", color: palette.text.main }} />
          <Typography
            variant="h3"
            sx={{ color: palette.text.main, fontWeight: "500" }}
          >
            Residential
          </Typography>
          <Typography
            variant="h4"
            sx={{
              color: palette.text.main,
              fontSize: "18px",
              fontWeight: "500",
            }}
          >
            Transform Your Home with Our Residential Electrical Services! Our
            experienced electricians specialize in everything from lighting
            installations to panel upgrades, ensuring your home is both safe and
            stylish.
          </Typography>
        </WidgetWrapper>
        <WidgetWrapper
          sx={{
            backgroundColor: palette.primary.main,
            width: cardWidth,
            height: cardHeight,
          }}
        >
          <BusinessIcon style={{ fontSize: "80", color: palette.text.main }} />
          <Typography
            variant="h3"
            sx={{ color: palette.text.main, fontWeight: "500" }}
          >
            Commercial
          </Typography>
          <Typography
            variant="h4"
            sx={{
              color: palette.text.main,
              fontSize: "18px",
              fontWeight: "500",
            }}
          >
            Empower Your Business with Our Commercial Electrical Services! Our
            team of certified electricians is dedicated to delivering top-tier
            solutions tailored to the unique needs of your commercial space.
            From lighting design and installation to power distribution and
            emergency repairs, we provide comprehensive electrical services to
            keep your business running smoothly.
          </Typography>
        </WidgetWrapper>
        <WidgetWrapper
          sx={{
            backgroundColor: palette.primary.main,
            width: cardWidth,
            height: cardHeight,
          }}
        >
          <FactoryIcon style={{ fontSize: "80", color: palette.text.main }} />
          <Typography
            variant="h3"
            sx={{ color: palette.text.main, fontWeight: "500" }}
          >
            Industrial
          </Typography>
          <Typography
            variant="h4"
            sx={{
              color: palette.text.main,
              fontSize: "18px",
              fontWeight: "500",
            }}
          >
            Elevate Your Industrial Operations with Our Electrical Services! Our
            dedicated team of skilled electricians specializes in providing
            comprehensive solutions for industrial settings. From large-scale
            installations to intricate system upgrades and maintenance, we
            ensure your electrical infrastructure runs seamlessly and
            efficiently.
          </Typography>
        </WidgetWrapper>
      </Box>
    </div>
  );
};

export default Service;
