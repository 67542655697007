import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Contact = () => {
  const { palette } = useTheme();
  const form = useRef();
  const isMobile = useMediaQuery("(max-width: 1175px)");

  const [isHovered, setIsHovered] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_rxcesiy",
        "template_1w2jzda",
        form.current,
        "8KMF4C3ArtSJoZxfo"
      )
      .then(
        (result) => {
          console.log(result.text);
          toast.success("Email sent successfully", {
            position: toast.POSITION.TOP_CENTER,
          });
        },
        (error) => {
          console.log(error.text);
          toast.error("Failed to send email", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      );
  };

  return (
    <div
      id="contact"
      style={{
        backgroundColor: palette.primary.main,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "12% 8%",
        gap: "2em",
      }}
    >
      <Typography
        color={palette.text.main}
        sx={{
          fontWeight: "700",
          fontSize: "42px",
        }}
      >
        Contact Us
      </Typography>

      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '20px', // Space between items
          padding: '2rem',
        }}
      >
        <form
          ref={form}
          onSubmit={sendEmail}
          style={{
            display: "inline-block",
            textAlign: "left",
            backgroundColor: "white",
            padding: "2.5rem",
            width: useMediaQuery("(max-width: 1175px)") ? "250px" : "400px",
            height: "50%",
            marginTop: "2rem",
          }}
        >
          <label>
            <Typography
              variant="h4"
              sx={{ color: palette.text.alt, fontWeight: "700" }}
            >
              EMAIL
            </Typography>
          </label>
          <input
            className="input-email"
            type="email"
            name="user_email"
            required="required"
            style={{
              width: "100%",
              padding: "10px",
              marginTop: "2px",
              marginBottom: "10px",
              boxSizing: "border-box",
              border: "1px solid",
            }}
          />
          <label>
            <Typography
              variant="h4"
              sx={{ color: palette.text.alt, fontWeight: "700" }}
            >
              SUBJECT
            </Typography>
          </label>
          <input
            className="input-subject"
            type="subject"
            name="subject"
            style={{
              width: "100%",
              padding: "10px",
              marginTop: "2px",
              marginBottom: "10px",
              boxSizing: "border-box",
              border: "1px solid",
            }}
          />
          <label>
            <Typography
              variant="h4"
              sx={{ color: palette.text.alt, fontWeight: "700" }}
            >
              MESSAGE
            </Typography>
          </label>
          <textarea
            className="textarea-message"
            name="message"
            required="required"
            style={{
              height: "200px",
              width: "100%",
              padding: "10px",
              marginTop: "2px",
              marginBottom: "10px",
              boxSizing: "border-box",
              border: "1px solid",
            }}
          />
          <input
            className="submit-button"
            type="submit"
            value="Send"
            style={{
              width: "100%",
              backgroundColor: isHovered
                ? palette.secondary.main
                : palette.primary.main,
              color: isHovered ? palette.text.alt : palette.text.main,
              padding: "10px",
              fontSize: "20px",
              fontWeight: "bold",
              marginTop: "2px",
              marginBottom: "10px",
              boxSizing: "border-box",
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          />
        </form>

        <Typography
          variant="h2"
          sx={{
            margin: "2rem",
            fontWeight: "700",
            color: palette.text.main,
            textAlign: "center",
          }}
        >
          OR
        </Typography>
        <Box>
          <Typography
            variant="h3"
            sx={{
              fontWeight: "700",
              color: palette.text.main,
              textAlign: "center",
            }}
          >
            Call us at 022 321 4500
          </Typography>
        </Box>
      </Box>
    </div>
  );
};

export default Contact;
