import React from "react";
import Navbar from "../components/navbar/index.jsx";
import Landing from "../components/landing/landing";
import About from "../components/about/about";
import Service from "../components/service/service";
import Contact from "../components/contact/contact";

const Home = () => {
  return (
    <>
      <Navbar />
      <Landing />
      <About />
      <Service />
      <Contact />
    </>
  );
};

export default Home;
